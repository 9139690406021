<template>
  <app-modal-open-payment-link-detail
    :show="showModalDetail"
    @close="showModalDetail = false"
    :open_payment_link="selectOpenPaymentLink"
    @update:open_payment_link="selectOpenPaymentLink = $event"
  />
  <app-modal-qr-code
    :show="showQrCode"
    @close="showQrCode = false"
    :model="selectModel"
  />
  <div class="w-full">
    <app-table
      :apiResponse="apiPaginationResponse.data"
      :loading="apiPaginationLoading"
      :showNumber="false"
      :filters="
        this.isMasterBusinessRole
          ? [
              {
                key: 'filter[business_id]',
                type: 'select',
                placeholder: $t('business.table.header_businss_name'),
                items: all_business,
              },
              {
                key: 'filter[title]',
                type: 'text',
                placeholder: $t('payment.open-payment-link.filter.title'),
                value: '',
              },
              {
                key: 'filter[currency]',
                type: 'text',
                placeholder: $t('payment.open-payment-link.filter.currency'),
                value: '',
              },
              {
                key: 'filter[minimum_amount]',
                type: 'number',
                placeholder: $t(
                  'payment.open-payment-link.filter.minimum_amount'
                ),
                value: '',
              },

              {
                key: 'filter[created_at_between]',
                type: 'date',
                value: '',
              },
            ]
          : [
              {
                key: 'filter[title]',
                type: 'text',
                placeholder: $t('payment.open-payment-link.filter.title'),
                value: '',
              },
              {
                key: 'filter[currency]',
                type: 'text',
                placeholder: $t('payment.open-payment-link.filter.currency'),
                value: '',
              },
              {
                key: 'filter[minimum_amount]',
                type: 'number',
                placeholder: $t(
                  'payment.open-payment-link.filter.minimum_amount'
                ),
                value: '',
              },
              {
                key: 'filter[created_at_between]',
                type: 'date',
                value: '',
              },
            ]
      "
      @params-changed="paramsChanged"
      @empty-button-clicked="emptyButtonClicked"
      :emptyButton="$t('payment.menu.create_link')"
      @on-item-click="onItemClick"
    >
      <template v-slot:title>
        <div>
          <p class="font-medium">
            {{ $t("payment.menu.open_link") }}
          </p>
        </div>
      </template>
      <template v-slot:button>
        <app-button
          width="w-auto"
          v-if="!dataEmpty"
          :showf70Icon="false"
          @click="emptyButtonClicked()"
        >
          <template v-slot:icon>
            <app-icon-outline name="PlusIcon" class="h-6 w-6 text-white mr-2" />
          </template>
          {{ $t("payment.menu.create_link") }}
        </app-button>
      </template>
      <template v-slot:header>
        <th class="font-bold">
          {{ $t("payment.open-payment-link.header.minimum_amount") }}
        </th>
        <th class="font-bold">
          {{ $t("payment.open-payment-link.header.title") }}
        </th>
        <th class="font-bold">
          {{ $t("payment.open-payment-link.header.date") }}
        </th>
        <th class="font-bold">
          {{ $t("payment.open-payment-link.header.status") }}
        </th>
        <th class="font-bold">
          {{ $t("payment.open-payment-link.header.action") }}
        </th>
      </template>
      <template v-slot:body="slotData">
        <td>
          <p class="font-medium">
            {{
              $formats.currency(
                slotData.model.currency,
                slotData.model.minimum_amount ?? 0.0
              )
            }}
          </p>
        </td>
        <td>
          {{ slotData.model.title }}
        </td>
        <td>
          {{ $moment(slotData.model.created_at).format("DD MMM YYYY hh:mm a") }}
        </td>
        <td @click.stop="">
          <div class="flex flex-row space-x-1">
            <app-form-switch
              :label="
                $t(
                  'payment.open-payment-link.status.' + slotData.model.status_id
                )
              "
              v-model="slotData.model.status"
              :id="slotData.model._id"
              :loading="itemChangeLoading"
              :confirmModal="confirmModal"
              @onChange="onActiveDeactive"
              :showSkipModal="false"
              @click.stop
            />
          </div>
        </td>
        <td @click.stop="">
          <div class="flex flex-row space-x-1">
            <!-- Edit -->
            <app-button-outline
              class="bg-white"
              padding="px-2 py-1"
              :disabled="!$lodash.isEmpty(slotData.model.payment_links)"
              @click="editOpenPaymentLink(slotData.model)"
            >
              <p class="text-xs">
                {{ $t("payment.open-payment-link.form.edit_details") }}
              </p>
            </app-button-outline>

            <!-- QR Code -->
            <app-button-outline
              class="bg-white"
              padding="px-2 py-1"
              @click="clickShowQrcode(slotData.model)"
            >
              <app-icon-outline
                name="QrcodeIcon"
                class="h-5 w-5 text-gray-400"
              />
              <p class="ml-1 text-xs">
                {{ $t("payment.open-payment-link.qrcode-page.qr_code") }}
              </p>
            </app-button-outline>

            <!-- Copy Link -->
            <app-button-outline
              class="bg-white"
              padding="px-2 py-1"
              @click="copyUrl(slotData.model)"
            >
              <app-icon-outline
                name="DocumentDuplicateIcon"
                class="h-5 w-5 text-gray-400"
              />
              <p class="ml-1 text-xs">
                {{ $t("payment.detail.copy_link") }}
              </p>
            </app-button-outline>
          </div>
        </td>
      </template>
    </app-table>
  </div>
</template>
<script>
import BUSINESS_STATUS from "@/utils/const/business_status";
export default {
  data() {
    return {
      showModalDetail: false,
      showQrCode: false,
      selectOpenPaymentLink: null,
      selectModel: null,
      confirmModal: {
        positive_title: this.$t(
          "payment.open-payment-link.modal.activate_deactivate.positive_title"
        ),
        negative_title: this.$t(
          "payment.open-payment-link.modal.activate_deactivate.negative_title"
        ),
        positive_desc: this.$t(
          "payment.open-payment-link.modal.activate_deactivate.positive_desc"
        ),
        negative_desc: this.$t(
          "payment.open-payment-link.modal.activate_deactivate.negative_desc"
        ),
        positive_action: this.$t(
          "payment.open-payment-link.modal.activate_deactivate.positive_action"
        ),
        negative_action: this.$t(
          "payment.open-payment-link.modal.activate_deactivate.negative_action"
        ),
        skip_prompt: this.$t(
          "payment.open-payment-link.modal.activate_deactivate.skip_prompt"
        ),
        skip_modal_store: "userStore/openPaymentLinkSkipPrompt",
        skip_modal_update_store: "userStore/setOpenPaymentLinkSkipPrompt",
      },
    };
  },

  mounted() {
    this.$store.dispatch("masterBusinessStore/getMasterBusiness", {
      business_status_id: BUSINESS_STATUS.APPROVED,
    });
    this.$store.dispatch("openPaymentLinkStore/retrieveOpenPaymentLinks");

    if (this.$route.query.id) {
      this.showModalOpenPaymentLinkById(this.$route.query.id);
    }
  },

  computed: {
    all_business() {
      return this.$store.getters["masterBusinessStore/all_business"];
    },
    apiPaginationResponse() {
      return this.$store.getters["openPaymentLinkStore/apiPaginationResponse"];
    },
    apiPaginationLoading() {
      return this.$store.getters["openPaymentLinkStore/apiPaginationLoading"];
    },
    itemChangeLoading() {
      return this.$store.getters["openPaymentLinkStore/item_change_loading"];
    },
    errors() {
      return this.$store.getters["openPaymentLinkStore/errors"];
    },
    dataEmpty() {
      return (
        this.$lodash.isEmpty(this.apiPaginationResponse?.data?.data) ?? null
      );
    },
    isMasterBusinessRole() {
      return this.$store.getters["authStore/isMasterBusinessRole"];
    },
  },

  methods: {
    editOpenPaymentLink(openPaymentLink) {
      this.$router.push({
        name: "payment-open-link.edit",
        params: {
          id: openPaymentLink._id,
        },
      });
    },

    async showModalOpenPaymentLinkById(id) {
      let result = await this.$store.dispatch(
        "openPaymentLinkStore/findOpenPaymentLink",
        id
      );

      if (!this.$lodash.isEmpty(result.data)) {
        this.selectOpenPaymentLink = result.data;
        this.showModalDetail = true;
      }
    },

    emptyButtonClicked() {
      this.$router.push({ path: "/payment/open-link/create" });
    },

    copyUrl(model) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;

      clipboardData.writeText(model.payment_url);

      this.$notify(
        {
          group: "success",
          title: this.$t("payment.link.copy_link"),
        },
        5000
      );
    },

    paramsChanged(filters, queryParams) {
      this.$store.dispatch(
        "openPaymentLinkStore/retrieveOpenPaymentLinks",
        queryParams
      );
    },

    onItemClick(data) {
      this.showModalDetail = !this.showModalDetail;
      this.selectOpenPaymentLink = data;
    },

    clickShowQrcode(data) {
      this.showQrCode = !this.showQrCode;
      this.selectModel = data;
    },

    async onActiveDeactive(item) {
      let open_payment_link = this.apiPaginationResponse.data?.data?.find(
        (open_payment_link) => open_payment_link._id == item.id
      );

      if (open_payment_link == null) return;

      let open_payment_link_index =
        this.apiPaginationResponse.data.data.indexOf(open_payment_link);

      await this.$store.commit(
        "openPaymentLinkStore/setOpenPaymentLink",
        open_payment_link
      );
      let updatedResult = await this.$store.dispatch(
        "openPaymentLinkStore/updateStatusOpenPaymentLink",
        item.value
      );

      if (updatedResult == null) {
        open_payment_link.status = !item.value;
        this.apiPaginationResponse.data.data[open_payment_link_index] =
          open_payment_link;
        return;
      }

      updatedResult.data.payment_summary = open_payment_link.payment_summary;

      this.apiPaginationResponse.data.data[open_payment_link_index] =
        updatedResult.data;
    },

    isEnabled(open_payment_link) {
      return open_payment_link.status_id == 1;
    },
  },
};
</script>
